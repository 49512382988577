.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*.scrollbarhide {
  overflow: auto;
  scrollbar-width: none;
}

.scrollbarhide::-webkit-scrollbar {
  width: 0;
  height: 0;
}*/

.custom-input {
  display: flex;
  flex-direction: row;
  position: relative;
  border: 0.5px solid #ced4da;
  border-radius: 5px;
  padding: 10px 12px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  background-color: transparent !important; /* Set the background to transparent */
  width: inherit;
}

.custom-input input {
  border: none;
  outline: none;
  width: 100%;
  box-shadow: none;
  padding: 0;
  background-color: transparent !important; /* Set the background to transparent */
}

.custom-input input::placeholder {
  color: #999;
}

.custom-input input:focus {
  /*  box-shadow: 0 0 4px rgba(0, 123, 255, 0.6);*/
  box-shadow: none;
  border: none;
}

/*.custom-input input:-internal-autofill-selected {
  box-shadow: none;
  border: none;
  background: transparent;
}

.custom-input input:-webkit-autofill {
  -webkit-box-shadow: none;
  background: transparent;
  appearance: none;
  color: none;
}*/

@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent !important;
}
/*input:-webkit-autofill {
  -webkit-background-clip: text;
}
input[data-autocompleted] {
  background-color: transparent !important;
}*/

.custom-input input:-webkit-autofill,
.custom-input input:-webkit-autofill:hover,
.custom-input input:-webkit-autofill:focus,
.custom-input input:-webkit-autofill:active {
  -webkit-background-clip: text;
  /*    -webkit-text-fill-color: #ffffff;*/
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent !important;
}

input[type="month"]::-webkit-calendar-picker-indicator {
  display: none;
}

.toast-error {
  background: #ffedf0;
  color: #fb0909;
  font-weight: 500;
  padding: 5px;
  @media screen and (max-width: 480px) {
    margin: 16px;
    border-radius: 6px;
  }
}

.toast-progress {
  /*  background: linear-gradient(285deg, #032ef7 12%, green 84%);*/
  background: #032ef7;
  height: 2px;
}

.toast-info {
  background: #d9ddfb;
  color: #0722ef;
  font-weight: 500;
  padding: 5px;
  @media screen and (max-width: 480px) {
    margin: 16px;
    border-radius: 6px;
  }
}

.toast-success {
  background: #f1fff3;
  color: #32cd32;
  font-weight: 500;
  padding: 5px;
  @media screen and (max-width: 480px) {
    margin: 16px;
    border-radius: 6px;
  }
}

.toast-warning {
  background: #f9decb;
  color: #ff6700;
  font-weight: 500;
  padding: 5px;
  @media screen and (max-width: 480px) {
    margin: 16px;
    border-radius: 6px;
  }
}

.rdrCalendarWrapper {
  width: 100%;
}

.rdrDayEndOfWeek {
  right: 10px;
}

.rdrMonth {
  width: -webkit-fill-available !important;
}

.rdrDateDisplayWrapper {
  background-color: transparent !important;
}

.rdrDateDisplayItem {
  border: 0.5px solid #ccc;
  box-shadow: none;
}

.rdrDateDisplayItemActive {
  border: 0.5px solid blue;
}

::-webkit-scrollbar-thumb {
  background: #5842bc !important;
  border-radius: 5px;
}

/* For Webkit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* For Internet Explorer and Edge */
-ms-overflow-style: none;

/* For Firefox and other browsers that support the scrollbar-width property */
scrollbar-width: none;

.element::-webkit-scrollbar {
  -webkit-appearance: none;
}

/*html, body {
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}*/

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  color-scheme: light dark;
}

.app {
  height: 100vh;
/*  overflow-y: auto;*/
}

#pdf-controls {
  background-color: rgba(80, 96, 255, 0.04);
  box-shadow: none;
}

/*.refresh-container {
  touch-action: pan-down;
  -webkit-overflow-scrolling: touch;
}*/
